import { useEffect, useRef, useState } from "react";
import CountUp from 'react-countup'

export default function RaffleProcessItem(props) {
	const itemRef = useRef()
	const textRef = useRef()
	const additionalRef = useRef()
	const [textTyped, setTextTyped] = useState(false)
	const [showBubble, setShowBubble] = useState(false)

	const typing = () => {
		let timeout = props.item.timeout ? props.item.timeout : 900
		if (props.item.typing) {
			let array = props.item.text.split('')
			setTimeout(() => {
				for (let k = 0; k < array.length; k++) {
					if (k === 0) setShowBubble(true);
					setTimeout(() => {
						if (textRef.current) {
							textRef.current.textContent += array[k]
						}

						if (k + 1 === array.length && !props.item.percents && !props.item.additional) props.callback()
						if (k + 1 === array.length && props.item.percents) setTextTyped(true)
						if (k + 1 === array.length && props.item.additional) additional()
					}, k * 25);
				}
			}, timeout);
		} else {
			setTimeout(() => {
				setShowBubble(true)
				if (textRef.current) {
					textRef.current.innerHTML = props.item.text
				}

				if (!props.item.percents && !props.item.additional) props.callback()
				if (props.item.percents) setTextTyped(true)
				if (props.item.additional) additional()
			}, timeout);
		}
	}

	const additional = () => {
		let timeout = props.item.additional.timeout ? props.item.additional.timeout : 900

		if (additionalRef.current) {
			setTimeout(() => {
				additionalRef.current.innerHTML = ' ' + props.item.additional.text
				props.callback()
			}, timeout)
		}
	}

	useEffect(() => {
		document.body.scrollIntoView({behavior: 'smooth', block: 'end'});
		typing()
	}, [])

	return (
		<div key={props.id} data-key={props.id} className='boxText_body' ref={itemRef} style={{opacity: (showBubble ? 1 : 0)}}>
			<span ref={textRef}></span>
			{props.item.percents && textTyped && <span>
				<CountUp
					start={0}
					prefix=' ('
					suffix='%)'
					end={100}
					onEnd={() => props.callback()}
				/>
			</span>}
			{props.item.additional && <b ref={additionalRef}></b>}
		</div>
	)
}
