import api from '@/api'

export default class SettingsApi {
	static async init(data) {
		const http = api();
		return http.post('/settings/init', data).then(function (response) {
			return response.data
		})
	}
}
