import React, { useEffect, useState } from 'react';
import Page from '@/components/Page';
import { useParams } from 'react-router-dom';
import { useExpand, useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import PaymentSelectCurrency from '../components/SelectCurrency';
import { useErrorBoundary } from 'react-error-boundary';
import PaymentForm from '../components/Form';
import { useTranslation } from 'react-i18next';

export default function PaymentPage() {
	const { t } = useTranslation()
	const params = useParams()
	const period = parseInt(params.period)
	const type = parseInt(params.type)
	const [isExpanded, expand] = useExpand();
	const [currency, setCurrency] = useState();
	const { showBoundary } = useErrorBoundary()
	const [impactOccurred, notificationOccurred] = useHapticFeedback();

	useEffect(() => {
		expand()
		if (!period || !type) showBoundary()
	}, [period, type])

	const handleSetCurrency = (code) => {
		impactOccurred('light')
		setCurrency(code)
	}

	return (
    	<Page title={ t('features.payment.title') }>
			{currency ?
				<PaymentForm period={period} type={type} currency={currency} handleSetCurrency={handleSetCurrency} />
			:
				<PaymentSelectCurrency period={period} type={type} handleSetCurrency={handleSetCurrency} />
			}
	    </Page>
	)
}
