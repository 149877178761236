import { getRaffle } from '@/features/Raffle/reducer';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RaffleApi from '../../api';
import useEnvInitData from '@/hooks/useEnvInitData'
import { useErrorBoundary } from 'react-error-boundary';
import RaffleProcessItem from './item';
import { bgColorsArray } from '@/constants'
import { useTranslation } from 'react-i18next';

export default function RaffleProcess() {
	const { showBoundary } = useErrorBoundary()
	const raffle = useSelector(getRaffle)
	const [steps, setSteps] = useState(false)
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const [stepsLoaded, setStepsLoaded] = useState(['loadUser'])
	const { t } = useTranslation()

	useEffect(() => {
		RaffleApi.process({
			initData: InitData,
			raffle: raffle.id
		}).then(function(result) {
			if (result) {
				setTimeout(() => setSteps(result), 2000)
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}, [])

	return (
		steps ?
			<div className='boxText'>
				<div className='boxText_header'>{ t('features.raffle.process.title') }</div>
				{Object.keys(steps).map((key) => {
					return stepsLoaded.includes(key) ?
						key === 'end' ?
							<div key={key}>
								{raffle.tickets.length > 0 &&
									<div className='boxFolder' style={{marginBottom: '6px'}}>
										{raffle.tickets.map((ticket) =>
											<a href={ticket.user.url} className='boxFolderItem' key={ticket.place}>
												<div className='boxFolderItem-img'>
													{ticket.user.photo ?
														<img src={ticket.user.photo} alt='' />
													:
														<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{ticket.user.name.substring(0, 1)}</span>
													}
												</div>
												<div className='boxFolderItem-info'>
													<div className='boxFolderItem-name'>{ticket.user.name}</div>
													<div className='boxFolderItem-ticket'>
														{ t('features.raffle.process.ticket') } <b>{ticket.ticket}</b>
														{ticket.replayed &&
															<i> { t('features.raffle.process.reWin') }</i>
														}
													</div>
												</div>
												<div className='boxFolderItem-count boxFolderItem-count--place'>
													<span>#{ticket.place}</span>
												</div>
											</a>
										)}
									</div>
								}
								<RaffleProcessItem key={key} id={key} item={steps[key]} stepsLoaded={stepsLoaded} callback={() => {
									if (steps[key].nextStep) setStepsLoaded([steps[key].nextStep, ...stepsLoaded])
								}} />
							</div>
						:
							<RaffleProcessItem key={key} id={key} item={steps[key]} stepsLoaded={stepsLoaded} callback={() => {
								if (steps[key].nextStep) setStepsLoaded([steps[key].nextStep, ...stepsLoaded])
							}} />
					: null
				})}
			</div>
		:
			null
	)
}
