import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		fallbackLng: "ru",
		lng: "ru",
		ns: ["translations"],
		defaultNS: "translations",
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: {
				translations: require("./locales/en/translation.json"),
			},
			ru: {
				translations: require("./locales/ru/translation.json"),
			},
		},
	});

i18n.languages = ["en", "ru"];

export default i18n;
