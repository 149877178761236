import api from '@/api'

export default class HomeApi {
	static async init(data) {
		const http = api();
		return http.post('/index', data).then(function (response) {
			return response.data
		})
	}
}
