import api from '@/api'

export default class RaffleApi {
	static async process(data) {
		const http = api();
		return http.post('/raffle/process', data).then(function (response) {
			return response.data
		})
	}
}
