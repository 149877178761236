import React from 'react';
import Page from '@/components/Page';
import ErrorComponent from '@/components/ErrorComponent';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
	const { t } = useTranslation()

  	return (
		<Page title={ t('error_page_title') }>
			<ErrorComponent />
		</Page>
	);
}
