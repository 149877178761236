import Page from '@/components/Page';
import RaffleProcess from '../components/process';
import { getRaffle } from '@/features/Raffle/reducer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import RaffleContent from '../components/Content';
import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { useNavigate } from 'react-router-dom';

export default function RaffleProcessPage() {
	const raffle = useSelector(getRaffle)
	const { showBoundary } = useErrorBoundary()
	const [isExpanded, expand] = useExpand();
	const navigate = useNavigate()

	useEffect(() => {
		if (!raffle) showBoundary();
		expand()
	}, [raffle])

	const back = () => {
		navigate(-1)
	}

	return (
    	<Page title={raffle.title}>
			<BackButton onClick={back} />
			<RaffleContent/>
			<RaffleProcess/>
	    </Page>
	)
}
