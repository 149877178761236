import { useDispatch, useSelector } from 'react-redux'
import { getUser, setUser } from '@/modules/User/reducer';
import { setRaffle, getRaffle } from '@/features/Raffle/reducer';
import AnimationFailure from '@/icons/animations/Failure.json'
import AnimationCrown from '@/icons/animations/Crown.json'
import Lottie from 'lottie-react';
import Countdown from 'react-countdown';
import { useEffect, useState } from 'react';
import { HandySvg } from 'handy-svg';
import WordDeclension from '@/utils/WordDeclension';
import { animationSizes , raffleStatusPreend, bgColorsArray } from '@/constants';
import { MainButton, useHapticFeedback, useWebApp, useExpand, useShowPopup } from '@vkruglikov/react-telegram-web-app';
import IconArrowDown from '@/icons/arrow-down.svg'
import MemberApi from '../api';
import useEnvInitData from '@/hooks/useEnvInitData'
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function MemberUnActive(props) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const [boxOpened, setBoxOpened] = useState(false)
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const { showBoundary } = useErrorBoundary()
	const WebApp = useWebApp();
	const [isExpanded, expand] = useExpand();

	const ended = () => {
		notificationOccurred('success')
		dispatch(setRaffle({
			...raffle,
			status: raffleStatusPreend
		}))
	}

	const [channelsFailure, setChannelsFailure] = useState([]);
	const [channelsSuccess, setChannelsSuccess] = useState([]);
	const [successChannelsPhotoCount, setSuccessChannelsPhotoCount] = useState(0);
	useEffect(() => {
		if (user.updated) setUpdated(true)

		let success = [];
		let failure = [];
		let tempSuccessChannelsPhotoCount = 0;
		for (let key in raffle.channels) {
			if (user.channels.includes(raffle.channels[key].id)) {
				failure.push(raffle.channels[key])
			} else {
				success.push(raffle.channels[key])
				if (raffle.channels[key].photo) {
					if (tempSuccessChannelsPhotoCount < 5) {
						tempSuccessChannelsPhotoCount++;
					}
				}
			}
		}
		setSuccessChannelsPhotoCount(tempSuccessChannelsPhotoCount)
		setChannelsSuccess(success)
		setChannelsFailure(failure)
		expand()
	}, [raffle, user, expand])

	const [sendToBot, setSendToBot] = useState(false)
	const handleToBot = () => {
		impactOccurred('soft')
		setSendToBot(true)

		MemberApi.toBot({
			initData: InitData,
			raffle: raffle.id
		}).then(function(result) {
			if (result) {
				notificationOccurred('success')
				setSendToBot(false)
				WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT)
				WebApp.close()
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}

	const [updated, setUpdated] = useState(false)
	const [creatorToMember, setCreatorToMember] = useState(false);
	const showPopup = useShowPopup();

	useEffect(() => {
		if (creatorToMember) {
			refresh();
		}
	}, [creatorToMember]);

	const refresh = () => {
		impactOccurred('rigid')

		if (user.is_creator && !creatorToMember) {
			showPopup({
				message: t('modules.member.unActive.creator_warning.text'),
				buttons: [
					{
						id: 'yes',
						text: t('modules.member.unActive.creator_warning.true'),
						type: 'destructive'
					},
					{
						id: 'cancel',
						text: t('modules.member.unActive.creator_warning.false'),
						type: 'cancel'
					}
				]
			}).then(button_id => {
				if (button_id === 'yes') {
					setCreatorToMember(true)
				}
			})
		} else {
			let updatedUser = {
				...user,
				channels: false,
				updated: true,
			}

			if (updatedUser.is_creator && creatorToMember) {
				updatedUser.creator_to_member = true;
			}

			dispatch(setUser(updatedUser));
		}
	}

	return (
		<>
			<div className='boxIntro'>
				<div className='boxIntro_sticker'>
					{user.is_creator ?
						<Lottie animationData={AnimationCrown} loop={false} style={animationSizes['AnimationCrown']} />
					:
						<Lottie animationData={AnimationFailure} loop={false} style={animationSizes['AnimationFailure']} />
					}
				</div>
				<div className='boxIntro_title'>{ user.is_creator ? t('modules.member.unActive.cretor_title') : t('modules.member.unActive.title') }</div>
				<div className='boxIntro_text'>{ user.is_creator ? t('modules.member.unActive.cretor_text') :t('modules.member.unActive.text') }</div>
				<div className='boxIntro_countdown'>
					<Countdown
						date={raffle.date_end_count}
						renderer={(props) => {
							if (props.completed) {
								ended()
								return t('modules.member.unActive.ended')
							} else {
								return (
									<span className='boxIntro_countdown-timer'>
										{props.days > 0 &&
											<>
												{props.days}<WordDeclension code='modules.member.unActive.days' count={props.days} />,
											</>
										}
										{' ' + props.formatted.hours}
										:{props.formatted.minutes}
										:{props.formatted.seconds}
									</span>
								)
							}
						}}
					/>
					<div className='boxIntro_countdown-text'>{ t('modules.member.unActive.toEnd') }</div>
				</div>
				<button className='btn btn--block btn--light btn--tt-none' onClick={props.openRaffle}>{ t('modules.member.unActive.aboutRaffle') }</button>
				{user.is_creator ?
					<Link className='btn btn--block btn--tt-none' to='/settings'>
						{ t('features.settings.link') }
					</Link>
				: null }
			</div>

			<div className='boxList'>
				{channelsFailure.map((channel) =>
					<a href={channel.link} className='boxList_item' key={channel.id}>
						{channel.photo ?
							<div className='boxList_item-icon'>
								<img src={channel.photo} alt='' />
							</div>
						:
							<div className='boxList_item-icon'>
								<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{channel.name.substring(0, 1)}</span>
							</div>
						}
						<div className='boxList_item-content'>
							<div className='boxList_item-title'>{channel.name}</div>
							<div className='boxList_item-hint'>{ t('modules.member.unActive.unSubscribed') }</div>
						</div>
						<div className='boxList_item-right'>
							<div className='btn btn--sm'>{ t('modules.member.unActive.subscribe') }</div>
						</div>
					</a>
				)}
				<button className='btn btn--block btn--tt-none' style={{marginTop: '6px'}} onClick={refresh} disabled={updated}>
					{ user.is_creator ? t('modules.member.unActive.creator_check') : t('modules.member.unActive.check') }
					{updated &&
						<Countdown
							date={Date.now() + 60000}
							renderer={(props) => {
								if (props.completed) {
									setUpdated(false)
								} else {
									return (
										<>
											{' (' + props.formatted.minutes}
											:{props.formatted.seconds + ')'}
										</>
									)
								}
							}}
						/>
					}
				</button>

				{channelsSuccess.length > 0 &&
					<div className={'boxList_box' + (boxOpened || channelsSuccess.length === 1 ? ' boxList_box--opened' : '')}>
						<button className='boxList_item boxList_box-open' onClick={() => setBoxOpened(true)}>
							{successChannelsPhotoCount > 0 &&
								<div className={'boxList_item-icons boxList_item-icons--' + successChannelsPhotoCount}>
									{channelsSuccess.slice(0, 5).map((channel) => {
										return channel.photo ? <img src={channel.photo} key={channel.id} alt='' /> : null;
									})}
								</div>
							}
							<div className='boxList_item-content'>
								<div className='boxList_item-title'>{ t('modules.member.unActive.all') }</div>
							</div>
						</button>

						<div className='boxList_box-header'>
							<div className='boxList_box-title'>{ t('modules.member.unActive.subscribedTitle') }</div>
							{channelsSuccess.length !== 1 && boxOpened &&
								<button className='boxList_box-close' onClick={() => setBoxOpened(false)}>
									<HandySvg src={IconArrowDown} />
									{ t('modules.member.unActive.hide') }
								</button>
							}
						</div>

						<div className='boxList_box-body'>
							{channelsSuccess.map((channel) =>
								<a href={channel.link} className='boxList_item' key={channel.id}>
									{channel.photo ?
										<div className='boxList_item-icon'>
											<img src={channel.photo} alt='' />
										</div>
									:
										<div className='boxList_item-icon'>
											<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{channel.name.substring(0, 1)}</span>
										</div>
									}
									<div className='boxList_item-content'>
										<div className='boxList_item-title'>{channel.name}</div>
										<div className='boxList_item-hint'>{ t('modules.member.unActive.subscribed') }</div>
									</div>
								</a>
							)}
						</div>
					</div>
				}
			</div>

			<MainButton
				text={sendToBot ? t('modules.member.unActive.sendingToTg') : t('modules.member.unActive.sendToTg')}
				onClick={handleToBot}
				progress={sendToBot}
				disabled={sendToBot}
			/>
		</>
	)
}

