import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	raffle: false,
  };

export const raffleSlice = createSlice({
	name: 'RaffleState',
	initialState,

  	reducers: {
		setRaffle: (state, action) => {
			state.raffle = action.payload;
		},
		unsetRaffle: (state) => {
			state.raffle = false;
		}
	},
})

export const { setRaffle, unsetRaffle } = raffleSlice.actions

export const getRaffle = (state) => state.RaffleState.raffle

export default raffleSlice.reducer
