import Load from '@/components/Load';
import { MainButton, useExpand, useHapticFeedback, useWebApp } from '@vkruglikov/react-telegram-web-app';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import WordDeclension from '@/utils/WordDeclension';
import { discounts } from '@/constants'
import Countdown from 'react-countdown';
import PaymentApi from '../api';
import useEnvInitData from '@/hooks/useEnvInitData'
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation, Trans } from 'react-i18next';

export default function PaymentForm(props) {
	const { t } = useTranslation()
	const { showBoundary } = useErrorBoundary()
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const WebApp = useWebApp();
	const [isExpanded, expand] = useExpand();
	const [InitDataUnsafe, InitData] = useEnvInitData();

	const [data, setData] = useState()

	const [copiedSum, setCopiedSum] = useState(false)
	const handleCopySum = () => {
		impactOccurred('medium')
		copy(data.amount)
		if (copiedSum) return false;
		setCopiedSum(true)
		setTimeout(() => setCopiedSum(false), 2500)
	}

	const [copiedAddress, setCopiedAddress] = useState(false)
	const handleCopyAddress = () => {
		impactOccurred('rigid')
		copy(data.address)
		if (copiedAddress) return false;
		setCopiedAddress(true)
		setTimeout(() => setCopiedAddress(false), 2500)
	}

	const [sendToBot, setSendToBot] = useState(false)
	const handleToBot = () => {
		impactOccurred('soft')
		setSendToBot(true)

		PaymentApi.cryptoToBot({
			initData: InitData,
			period: props.period,
			currency: props.currency,
			type: props.type,
			id: data.id,
		}).then(function(result) {
			if (result) {
				notificationOccurred('success')
				setSendToBot(false)
				WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT)
				WebApp.close()
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}

	const [canReCreate, setCanReCreate] = useState(false)
	const handleReCreate = () => {
		if (!canReCreate) return false;
		impactOccurred('heavy')
		setData('')
		setCanReCreate(false)
		PaymentApi.cryptoCreate({
			initData: InitData,
			period: props.period,
			currency: props.currency,
			type: props.type,
			new: true
		}).then(function(result) {
			if (result && result.data) {
				notificationOccurred('success')
				setData(result.data)
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}

	useEffect(() => {
		if (!isExpanded) expand();
		PaymentApi.cryptoCreate({
			initData: InitData,
			period: props.period,
			currency: props.currency,
			type: props.type,
		}).then(function(result) {
			if (result && result.data) {
				setData(result.data)
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}, [props])

	return (
		!data ?
			<Load/>
		:
			<>
				<div className='boxText'>
					<div className='boxText_header'>{ t('features.payment.form.title') }</div>
					<div className='boxText_body'>
						<p>
							{ t('features.payment.form.text1') }<br/>
							{props.period}<WordDeclension count={props.period} code='features.payment.form.months' /> - <b>{data.price} ₽</b>
							{
								discounts[props.type] ?
									discounts[props.type][props.period] !== 0 && ' (' + t('features.payment.form.discount') + ' ' + discounts[props.type][props.period] + '%)'
								:
									null
							}
						</p>
						<Trans i18nKey="features.payment.form.text2" components={{ p: <p />, br: <br />, b: <b /> }} values={{ currency: props.currency }} />
						<Trans i18nKey="features.payment.form.text3" components={{ ol: <ol />, li: <li />, b: <b /> }} />
					</div>
				</div>

				<div className='boxText'>
					<div className='boxText_header'>{ t('features.payment.form.left') }</div>
					<div className='boxText_body'>
						<Countdown
							date={data.expired}
							renderer={(propCd) => {
								if (propCd.completed) {
									props.handleSetCurrency('')
								} else {
									return (
										<div className='boxLink'>
											{propCd.formatted.hours}
											:{propCd.formatted.minutes}
											:{propCd.formatted.seconds}
										</div>
									)
								}
							}}
						/>
						<button className='btn btn--block btn--tt-none' onClick={handleReCreate} disabled={!canReCreate}>
							{ t('features.payment.form.new') }
							{!canReCreate &&
								<Countdown
									date={Date.now() + 60000}
									renderer={(propCd) => {
										if (propCd.completed) {
											setCanReCreate(true)
										} else {
											return (
												<>
													{' (' + propCd.formatted.hours}
													:{propCd.formatted.minutes}
													:{propCd.formatted.seconds + ')'}
												</>
											)
										}
									}}
								/>
							}
						</button>
					</div>
				</div>

				<div className='boxText'>
					<div className='boxText_header'>{ t('features.payment.form.wallet') }</div>
					<div className='boxText_body'>
						<div className='boxLink' onClick={handleCopyAddress}>{!copiedAddress ? data.address : t('features.payment.form.copied')}</div>
						<button className='btn btn--block btn--tt-none' onClick={handleCopyAddress}>{!copiedAddress ? t('features.payment.form.copy') : t('features.payment.form.copied')}</button>
					</div>
				</div>

				<div className='boxText'>
					<div className='boxText_header'>{ t('features.payment.form.total') } <b>({props.currency})</b></div>
					<div className='boxText_body'>
						<div className='boxLink' onClick={handleCopySum}>{!copiedSum ? data.amount : t('features.payment.form.copied')}</div>
						<button className='btn btn--block btn--tt-none' onClick={handleCopySum}>{!copiedSum ? t('features.payment.form.copy') : t('features.payment.form.copied')}</button>
					</div>
				</div>

				<MainButton
					text={sendToBot ? t('features.payment.form.sending') : t('features.payment.form.send')}
					progress={sendToBot}
					disabled={sendToBot}
					onClick={handleToBot}
				/>
			</>
	)
}
