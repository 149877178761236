import BottomSheet from "react-draggable-bottom-sheet";
import IconClose from "@/icons/close.svg";
import { HandySvg } from 'handy-svg';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from '@/modules/User/reducer';
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

export default function SettingsItem({open, item, close}) {
	const { t } = useTranslation()
	const user = useSelector(getUser)
	const WebApp = useWebApp();

	return (
		<BottomSheet isOpen={open} close={close} disabled>
			<button className='BottomSheetClose' onClick={close}><HandySvg src={IconClose}/></button>
			<div className='BottomSheetTitle'>{t('features.settings.commands.' + item.key + '.title')}</div>

			<div className='boxIntro'>
				<div className='boxIntro_icon' style={{background: item.color}}>
					{item.icon}
				</div>

				<div className='boxIntro_text'>{ t('features.settings.commands.' + item.key + '.text') }</div>

				{!user.is_vip && item.vip ?
					<div className='boxIntro_text'>{ t('features.settings.vip_text') }</div>
				: null }

				{(user.is_vip && item.vip) || !item.vip ?
					<button
						className='btn btn--block btn--tt-none'
						onClick={() => WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT + '?start=' + item.url)}
					>
						{ t('features.settings.use_btn') }
					</button>
				: null }
				{!user.is_vip && item.vip ?
					<button
						className='btn btn--block btn--light btn--tt-none'
						onClick={() => WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT + '?start=vip-clear')}
					>
						{ t('features.settings.vip_btn') }
					</button>
				: null }
			</div>
		</BottomSheet>
	)
}
