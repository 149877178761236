import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import Lottie from 'lottie-react';
import AnimationFailure from '@/icons/animations/Failure.json'
import { animationSizes } from '@/constants'
import { useTranslation } from 'react-i18next';

export default function UserNotAccessPm(props) {
	const WebApp = useWebApp();
	const { t } = useTranslation()

	const callback = () => {
		WebApp.requestWriteAccess(props.callback())
	}

	return (
		<div className='boxIntro'>
			<div className='boxIntro_sticker'>
				<Lottie animationData={AnimationFailure} loop={false} style={animationSizes['AnimationFailure']} />
			</div>
			<div className='boxIntro_title'>{ t('modules.user.notAccessPm.title') }</div>
			<div className='boxIntro_text'>{ t('modules.user.notAccessPm.text') }</div>
			<button className='btn btn--tt-none btn-block' onClick={callback}>{ t('modules.user.notAccessPm.btn') }</button>
		</div>
	)
}
