import { useDispatch, useSelector } from 'react-redux'
import { getUser, setUser } from '@/modules/User/reducer';
import { getRaffle } from '@/features/Raffle/reducer';
import { animationSizes } from '@/constants'
import Lottie from 'lottie-react';
import AnimationSearch from '@/icons/animations/Search.json'
import { useEffect } from 'react';
import MemberApi from '../api';
import { useErrorBoundary } from 'react-error-boundary';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import useEnvInitData from '@/hooks/useEnvInitData'
import { useTranslation } from 'react-i18next';

export default function MemberNew() {
	const { t } = useTranslation()
	const { showBoundary } = useErrorBoundary()
	const dispatch = useDispatch()
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const [impactOccurred, notificationOccurred] = useHapticFeedback();

	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)

	useEffect(() => {
		const requestData = {
			initData: InitData,
			raffle: raffle.id
		}

		if (user.creator_to_member) {
			requestData.creator_confirm = true;
		}

		MemberApi.check(requestData).then(function(result) {
			if (!result) {
				showBoundary();
			}

			setTimeout(() => {
				if (result.tickets) {
					notificationOccurred('success')
				} else {
					notificationOccurred('error')
				}
				dispatch(setUser({
					...user,
					...result,
				}))
			}, 2000)

		}).catch((e) => {
			showBoundary(e)
		})
	})

	return (
		<>
			<div className='boxIntro'>
				<div className='boxIntro_sticker'>
					<Lottie animationData={AnimationSearch} loop={true} style={animationSizes['AnimationSearch']} />
				</div>
				<div className='boxIntro_title'>{ t('modules.member.new.title') }</div>
				<div className='boxIntro_text'>{ t('modules.member.new.text') }</div>
			</div>
		</>
	)
}
