import { useHapticFeedback, useWebApp } from "@vkruglikov/react-telegram-web-app";
import useEnvInitData from '@/hooks/useEnvInitData'
import { useErrorBoundary } from "react-error-boundary";
import MemberApi from "../api";
import { useTranslation } from "react-i18next";

export default function MemberShareBtn(props) {
	const WebApp = useWebApp();
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const { showBoundary } = useErrorBoundary()
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const { t } = useTranslation()

	const handleShare = () => {
		impactOccurred('medium')
		MemberApi.share({
			initData: InitData,
			raffle: props.raffle
		}).then(function(result) {
			if (result) {
				notificationOccurred('success')
				WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT)
				WebApp.close()
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}

	return (
		<button className='btn btn--light btn--tt-none' onClick={handleShare}>{ t('modules.member.shareBtn') }</button>
	)
}
