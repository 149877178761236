import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUser } from "@/modules/User/reducer";
import { getRaffle, setRaffle } from "@/features/Raffle/reducer";
import BottomSheet from "react-draggable-bottom-sheet";
import { useEffect, useRef, useState } from "react";
import {
	useHapticFeedback,
	useWebApp,
	MainButton,
} from "@vkruglikov/react-telegram-web-app";
import { raffleStatusPreend, animationSizes } from "@/constants";
import AnimationSuccess from "@/icons/animations/Success.json";
import AnimationStar from "@/icons/animations/Star.json";
import Lottie from "lottie-react";
import Countdown from "react-countdown";
import WordDeclension from "@/utils/WordDeclension";
import { HandySvg } from "handy-svg";
import IconStar from "@/icons/star.svg";
import MemberApi from "../api";
import { useErrorBoundary } from "react-error-boundary";
import useEnvInitData from "@/hooks/useEnvInitData";
import Alert from "@/components/Alert";
import IconPlusO from "@/icons/plus-o.svg";
import IconMinusO from "@/icons/minus-o.svg";
import IconClose from "@/icons/close.svg";
import { Link } from "react-router-dom";

export default function MemberPay(props) {
	const { t } = useTranslation();
	const raffle = useSelector(getRaffle);
	const user = useSelector(getUser);
	const [boxTicketsOpened, setBoxTicketsOpened] = useState(false);
	const dispatch = useDispatch();
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const WebApp = useWebApp();
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const { showBoundary } = useErrorBoundary();

	const ended = () => {
		notificationOccurred("success");
		dispatch(
			setRaffle({
				...raffle,
				status: raffleStatusPreend,
			})
		);
	};

	const [isSubOpen, setIsSubOpen] = useState(false);
	const subClose = () => setIsSubOpen(false);

	const [msg, setMsg] = useState(false);
	const [buying, setBuying] = useState(false);
	const [isBuyOpen, setIsBuyOpen] = useState(false);
	const buyClose = () => setIsBuyOpen(false);

	const ticketCountRef = useRef(1);
	const [ticketCount, setTicketCount] = useState(1);
	const [ticketCountDisableMin, setTicketCountDisableMin] = useState(true);
	const [ticketCountDisableMax, setTicketCountDisableMax] = useState(false);
	const maxTicketCountOnce = raffle.ticket_count === 0 ? 100 : raffle.ticket_count;

	useEffect(() => {
		setTicketCountDisableMin(ticketCount <= 1);
		setTicketCountDisableMax(
			(ticketCount >= maxTicketCountOnce - user.tickets.length &&
				raffle.ticket_count !== 0) ||
				(ticketCount >= maxTicketCountOnce && raffle.ticket_count === 0)
		);
	}, [
		ticketCount,
		maxTicketCountOnce,
		user.tickets.length,
		raffle.ticket_count,
	]);

	const handleChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');
		ticketCountRef.current.value = value

		if (value === '') {
			setTicketCount(1);
		} else if (value < 1) {
			setTicketCount(1);
			ticketCountRef.current.value = 1;
		} else if (value > maxTicketCountOnce) {
			setTicketCount(maxTicketCountOnce);
			ticketCountRef.current.value = parseInt(maxTicketCountOnce);
		} else {
			setTicketCount(parseInt(value));
		}
	};

	const handleDecrement = () => {
		if (ticketCount > 1) {
			const newValue = ticketCount - 1;
			setTicketCount(newValue);
			ticketCountRef.current.value = newValue;
		}
	};

	const handleIncrement = () => {
		if (
			(ticketCount < maxTicketCountOnce - user.tickets.length) ||
			(ticketCount < maxTicketCountOnce && raffle.ticket_count === 0)
		) {
			const newValue = ticketCount + 1;
			setTicketCount(newValue);
			ticketCountRef.current.value = newValue;
		}
	};

	const handleBuy = () => {
		impactOccurred("soft");
		setBuying(true);

		MemberApi.buy({
			initData: InitData,
			raffle: raffle.id,
			count: ticketCount,
		})
			.then(function (result) {
				setBuying(false);
				if (result.invoice) {
					WebApp.openInvoice(result.invoice, (status) => {
						notificationOccurred("success");
						setIsBuyOpen(false);
						if (status === "paid") {
							handleUpdateInfo();
						} else if (status === "pending") {
							setTimeout(handleUpdateInfo, 2000);
						}
					});
				} else if (result.message) {
					notificationOccurred("warning");
					setMsg(result.message);
					setTimeout(() => {
						setMsg(false);
					}, 3000);
				} else {
					showBoundary();
				}
			})
			.catch((e) => {
				showBoundary(e);
			});
	};

	const handleUpdateInfo = () => {
		MemberApi.get({
			initData: InitData,
			raffle: raffle.id,
		})
			.then(function (result) {
				if (result.tickets) {
					dispatch(
						setUser({
							...user,
							...result,
						})
					);
				} else {
					showBoundary();
				}
			})
			.catch((e) => {
				showBoundary(e);
			});
	};

	return (
		<>
			<div className="boxIntro">
				<div className="boxIntro_sticker">
					{user.tickets.length > 0 ? (
						<Lottie
							animationData={AnimationSuccess}
							loop={false}
							style={animationSizes["AnimationSuccess"]}
						/>
					) : (
						<Lottie
							animationData={AnimationStar}
							loop={false}
							style={animationSizes["AnimationStar"]}
						/>
					)}
				</div>
				{user.tickets.length > 0 ? (
					<>
						<div className="boxIntro_title">
							{t("modules.member.pay.title.done")}
						</div>
						<div className="boxIntro_text">
							<Trans
								i18nKey="modules.member.pay.text.end"
								components={{ b: <b /> }}
								values={{ price: raffle.ticket_price }}
							/>
							<br />
							<Trans
								i18nKey="modules.member.pay.text.ticketsCount"
								components={{ b: <b /> }}
								values={{ count: user.tickets.length }}
							/>
							<WordDeclension
								code="modules.member.pay.text.tickets"
								count={user.tickets.length}
							/>
						</div>
					</>
				) : (
					<>
						<div className="boxIntro_title">
							{t("modules.member.pay.title.not")}
						</div>
						<div className="boxIntro_text">
							<Trans
								i18nKey="modules.member.pay.text.end"
								components={{ b: <b /> }}
								values={{ price: raffle.ticket_price }}
							/>
						</div>
					</>
				)}
				<div className="boxIntro_countdown">
					<Countdown
						date={raffle.date_end_count}
						renderer={(props) => {
							if (props.completed) {
								ended();
								return t("modules.member.pay.ended");
							} else {
								return (
									<span className="boxIntro_countdown-timer">
										{props.days > 0 && (
											<>
												{props.days}
												<WordDeclension
													code="modules.member.pay.days"
													count={props.days}
												/>
												,
											</>
										)}
										{" " + props.formatted.hours}:
										{props.formatted.minutes}:
										{props.formatted.seconds}
									</span>
								);
							}
						}}
					/>
					<div className="boxIntro_countdown-text">
						{t("modules.member.pay.toEnd")}
					</div>
				</div>
				<button className="btn btn--block btn--light btn--tt-none" onClick={props.openRaffle}>
					{t("modules.member.pay.aboutRaffle")}
				</button>
				{user.is_creator ?
					<Link className='btn btn--block btn--tt-none' to='/settings'>
						{ t('features.settings.link') }
					</Link>
				: null }
			</div>

			<div className="boxList">
				{user.tickets.length > 0 ? (
					<>
						{raffle.public ? (
							<div
								className="boxList_item boxList_item--subscription"
								onClick={() => setIsSubOpen(true)}
							>
								<div className="boxList_item-icon boxList_item-icon--svg">
									<HandySvg src={IconStar} />
								</div>
								<div className="boxList_item-content">
									<div className="boxList_item-title">
										{t(
											"modules.member.pay.subscribeBtn.title"
										)}
									</div>
									<div className="boxList_item-text">
										{t(
											"modules.member.pay.subscribeBtn.text"
										)}
									</div>
								</div>
							</div>
						) : null}

						{user.tickets.slice(0, 1).map((ticket) => (
							<div className="boxList_item" key={ticket.ticket}>
								<div className="boxList_item-icon boxList_item-icon--ticket">
									<img
										src={
											process.env.PUBLIC_URL +
											"/img/gold-ticket.png"
										}
										alt=""
									/>
								</div>
								<div className="boxList_item-content">
									<div className="boxList_item-title">
										{t("modules.member.pay.ticket.name")}{" "}
										<b>{ticket.ticket}</b>
									</div>
									<div className="boxList_item-hint">
										{t("modules.member.pay.ticket.date")}{" "}
										<b>{ticket.created}</b>
									</div>
								</div>
							</div>
						))}

						{user.tickets.length === 2 ? (
							user.tickets.slice(1).map((ticket) => (
								<div
									className="boxList_item"
									key={ticket.ticket}
								>
									<div className="boxList_item-icon boxList_item-icon--ticket">
										<img
											src={
												process.env.PUBLIC_URL +
												"/img/gold-ticket.png"
											}
											alt=""
										/>
									</div>
									<div className="boxList_item-content">
										<div className="boxList_item-title">
											{t(
												"modules.member.pay.ticket.name"
											)}{" "}
											<b>{ticket.ticket}</b>
										</div>
										<div className="boxList_item-hint">
											{t(
												"modules.member.pay.ticket.date"
											)}{" "}
											<b>{ticket.created}</b>
										</div>
									</div>
								</div>
							))
						) : user.tickets.length > 2 ? (
							<div
								className={
									"boxList_box boxList_box--without-header" +
									(boxTicketsOpened
										? " boxList_box--opened"
										: "")
								}
							>
								<button
									className="boxList_item boxList_box-open"
									onClick={() => setBoxTicketsOpened(true)}
								>
									<div className="boxList_item-icon boxList_item-icon--ticket">
										<img
											src={
												process.env.PUBLIC_URL +
												"/img/gold-ticket.png"
											}
											alt=""
										/>
									</div>
									<div className="boxList_item-content">
										<div className="boxList_item-title">
											{t("modules.member.pay.ticket.all")}{" "}
											({user.tickets.length})
										</div>
									</div>
								</button>

								<div className="boxList_box-body">
									{user.tickets.slice(1).map((ticket) => (
										<div
											className="boxList_item"
											key={ticket.ticket}
										>
											<div className="boxList_item-icon boxList_item-icon--ticket">
												<img
													src={
														process.env.PUBLIC_URL +
														"/img/gold-ticket.png"
													}
													alt=""
												/>
											</div>
											<div className="boxList_item-content">
												<div className="boxList_item-title">
													{t(
														"modules.member.pay.ticket.name"
													)}{" "}
													<b>{ticket.ticket}</b>
												</div>
												<div className="boxList_item-hint">
													{t(
														"modules.member.pay.ticket.date"
													)}{" "}
													<b>{ticket.created}</b>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						) : null}
					</>
				) : null}
			</div>

			{user.tickets.length > 0 && raffle.public ? (
				<BottomSheet isOpen={isSubOpen} close={subClose} disabled>
					<button className="BottomSheetClose" onClick={subClose}>
						<HandySvg src={IconClose}/>
					</button>
					<div className="boxText">
						<div className="boxText_body">
							<Trans
								i18nKey="modules.member.pay.subscribe.text"
								components={{ p: <p />, b: <b /> }}
							/>
						</div>
						<button
							className="btn btn--tt-none btn--block"
							onClick={() =>
								WebApp.openTelegramLink(
									process.env.REACT_APP_LINK_TO_BOT +
										"?start=subscription"
								)
							}
						>
							{t("modules.member.pay.subscribe.btn")}
						</button>
					</div>
				</BottomSheet>
			) : null}

			{msg && <Alert>{msg}</Alert>}

			{raffle.ticket_count === 0 || raffle.ticket_count > user.tickets.length ? (
				<>
					<MainButton
						onClick={() => {
							isBuyOpen ? handleBuy() : setIsBuyOpen(true);
						}}
						text={
							isBuyOpen
								? t("modules.member.pay.buy.btn")
								: user.tickets.length > 0
								? t("modules.member.pay.payBtn.titleMore")
								: t("modules.member.pay.payBtn.title")
						}
						progress={buying}
						disabled={buying}
						color="#F7AD08"
						textColor="#fff"
					/>

					<BottomSheet isOpen={isBuyOpen} close={buyClose} disabled>
						<button className="BottomSheetClose" onClick={buyClose}>
							<HandySvg src={IconClose}/>
						</button>
						<div
							className="boxText"
							style={{ paddingBottom: "200px" }}
						>
							<div className="boxText_body">
								<p>{t("modules.member.pay.buy.text")}</p>
								<div className="boxFormCounter">
									<button
										className="btn"
										onClick={handleDecrement}
										disabled={ticketCountDisableMin}
									>
										<HandySvg src={IconMinusO} />
									</button>
									<input
										defaultValue={ticketCount}
										type="number"
										onInput={handleChange}
										ref={ticketCountRef}
									/>
									<button
										className="btn"
										onClick={handleIncrement}
										disabled={ticketCountDisableMax}
									>
										<HandySvg src={IconPlusO} />
									</button>
								</div>
								<p className="boxFormCounter-text">
									{t("modules.member.pay.buy.total")}{" "}
									<span>
										{ticketCount * raffle.ticket_price}{" "}
										Stars
									</span>
								</p>
							</div>
						</div>
					</BottomSheet>
				</>
			) : null}
		</>
	);
}
