import React, { useEffect } from 'react';
import Providers from './Providers';
import AppRouter from './router';
import { useThemeParams, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useTranslation } from 'react-i18next';
import useEnvInitData from '@/hooks/useEnvInitData'

export default function App() {
	const WebApp = useWebApp();
	const [colorScheme, themeParams] = useThemeParams();
	const { i18n } = useTranslation();
	const [InitDataUnsafe, InitData] = useEnvInitData();

	useEffect(() => {
		WebApp.setHeaderColor('secondary_bg_color')
		WebApp.setBackgroundColor('secondary_bg_color')
		document.body.classList.add('theme-' + (colorScheme !== 'dark' ? 'dark' : 'dark'));

		if (InitDataUnsafe) {
			if (InitDataUnsafe.user.language_code === 'ru') {
				i18n.changeLanguage('ru');
			} else {
				i18n.changeLanguage('en');
			}
		}
	}, [WebApp, colorScheme, InitDataUnsafe]);

	return (
		<Providers>
			<AppRouter />
		</Providers>
	);
};
