import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeRouter from '@Home/router'
import PaymentRouter from '@/features/Payment/router'
import RaffleRouter from '@/features/Raffle/router'
import SettingsRouter from '@/features/Settings/router'
import NotFoundPage from '@/pages/NotFoundPage'
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';

function AppRouter() {
    return (
        <BrowserRouter basename={'/'}>
			<Header />
				<Routes>
					{ HomeRouter }
					{ RaffleRouter }
					{ PaymentRouter }
					{ SettingsRouter }
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			<Footer />
        </BrowserRouter>
    );
}

export default AppRouter;
