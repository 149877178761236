import React, { useEffect, useState } from 'react';
import useEnvInitData from '@/hooks/useEnvInitData'
import PaymentApi from '../api';
import { useErrorBoundary } from 'react-error-boundary';
import Load from '@/components/Load';
import WordDeclension from '@/utils/WordDeclension';
import { discounts } from '@/constants'
import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { Trans, useTranslation } from 'react-i18next';

export default function PaymentSelectCurrency(props) {
	const { t } = useTranslation()
	const { showBoundary } = useErrorBoundary()
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const [currencies, setCurrencies] = useState()
	const [price, setPrice] = useState()
	const [isExpanded, expand] = useExpand();

	useEffect(() => {
		if (!isExpanded) expand();

		PaymentApi.cryptoGet({
			initData: InitData,
			period: props.period,
			type: props.type
		}).then(function(result) {
			if (result.price && result.currencies) {
				setCurrencies(result.currencies)
				setPrice(result.price)
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}, [])

	return (
		price && currencies ?
			<>
				<div className='boxText'>
					<div className='boxText_header'>{ t('features.payment.select.title') }</div>
					<div className='boxText_body'>
						<p>
							{ t('features.payment.select.text1') } <br/>
							{props.period} <WordDeclension count={props.period} code='features.payment.select.months' /> - <b>{price} ₽</b>
							{
								discounts[props.type] ?
									discounts[props.type][props.period] !== 0 && ' (' + t('features.payment.select.discount') + ' ' + discounts[props.type][props.period] + '%)'
								:
									null
							}
						</p>
						<Trans i18nKey="features.payment.select.text2" components={{ p: <p />, br: <br />, b: <b /> }} />
					</div>
				</div>

				<div className='boxTitle'>{ t('features.payment.select.select') }</div>
				<div className='boxFolder'>
					{currencies.map((currency) =>
						<button className='boxFolderItem' key={currency.code} onClick={() => props.handleSetCurrency(currency.code)}>
							<div className='boxFolderItem-img'>
								<img src={process.env.PUBLIC_URL + '/img/' + currency.code + '.png'} alt='' />
							</div>
							<div className='boxFolderItem-info'>
								<div className='boxFolderItem-name'>{currency.code}</div>
								<div className='boxFolderItem-ticket'>{currency.name}</div>
							</div>
						</button>
					)}
				</div>
			</>
		:
			<Load/>
	)
}
