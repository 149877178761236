import { Route } from 'react-router-dom'
import PaymentPage from './pages/PaymentPage'
import TinkoffPage from './pages/TinkoffPage'

const routes = [
	<Route path={'/payment/:type/:period'} element={<PaymentPage />} key='payment' />,
	<Route path={'/tinkoff/:type/:period'} element={<TinkoffPage />} key='tinkoff' />,
]

export default routes
