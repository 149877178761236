import Lottie from 'lottie-react';
import AnimationSearch from '@/icons/animations/Search.json'
import { animationSizes } from '@/constants'
import { useTranslation } from 'react-i18next';

export default function Load() {
	const { t } = useTranslation()

	return (
		<div className='boxIntro'>
			<div className='boxIntro_sticker'>
				<Lottie animationData={AnimationSearch} style={animationSizes['AnimationSearch']} />
			</div>
			<div className='boxIntro_title'>{ t('loading') }</div>
		</div>
	)
}
