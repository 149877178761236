import './style.scss'
import AnimationCopy from '@/icons/animations/Copy.json'
import Lottie from "lottie-react"

export default function Alert(props) {
	let className = 'Alert'
	let icon = <Lottie animationData={AnimationCopy} className='AlertIcon' loop={false} />
	if (props.danger) {
		className += ' Alert--danger'
		icon = null
	}
	return (
		<div className={className}>
			{icon}
			<div className='AlertText'>{props.children}</div>
		</div>
	)
}
