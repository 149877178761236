import api from '@/api'

export default class PaymentApi {
	static async cryptoGet(data) {
		const http = api();
		return http.post('/payments/crypto-get', data).then(function (response) {
			return response.data
		})
	}

	static async cryptoCreate(data) {
		const http = api();
		return http.post('/payments/crypto-create', data).then(function (response) {
			return response.data
		})
	}

	static async cryptoToBot(data) {
		const http = api();
		return http.post('/payments/crypto-to-bot', data).then(function (response) {
			return response.data
		})
	}

	static async tinkoffGet(data) {
		const http = api();
		return http.post('/payments/tinkoff-get', data).then(function (response) {
			return response.data
		})
	}

	static async tinkoffCreate(data) {
		const http = api();
		return http.post('/payments/tinkoff-create', data).then(function (response) {
			return response.data
		})
	}
}
