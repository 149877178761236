import { useExpand, useHapticFeedback, useWebApp } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useRef, useState } from "react"
import { useTranslation, Trans } from "react-i18next"
import PaymentApi from "../api";
import useEnvInitData from '@/hooks/useEnvInitData'
import { useErrorBoundary } from "react-error-boundary";
import Load from '@/components/Load';
import WordDeclension from '@/utils/WordDeclension';
import { discounts } from '@/constants'
import Alert from "@/components/Alert";
import { docs } from '@/constants'

export default function TinkoffForm(props) {
	const { t } = useTranslation()
	const [data, setData] = useState()
	const [isExpanded, expand] = useExpand();
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const { showBoundary } = useErrorBoundary()
	const WebApp = useWebApp();
	const [impactOccurred, notificationOccurred] = useHapticFeedback();

	const inputEmail = useRef(null)
	// const checkRecurrent = useRef(null)
	const checkTerms = useRef(null)
	const [error, setError] = useState(false)

	useEffect(() => {
		if (!isExpanded) expand();
		PaymentApi.tinkoffGet({
			initData: InitData,
			period: props.period,
			type: props.type,
		}).then(function(result) {
			if (result && result.data) {
				setData(result.data)
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}, [props])

	const handleSend = () => {
		if (!inputEmail.current.value) {
			setAlert('features.payment.tinkoff.form.validate')
			return false;
		}
		if (!checkTerms.current.checked) { //!checkRecurrent.current.checked &&
			setAlert('features.payment.tinkoff.form.validate_terms')
			return false
		}

		PaymentApi.tinkoffCreate({
			initData: InitData,
			period: props.period,
			type: props.type,
			email: inputEmail.current.value,
			recurrent: false,
			terms: checkTerms.current.checked
		}).then(function(result) {
			if (result) {
				if (result.redirect) {
					notificationOccurred('success')
					WebApp.openLink(result.redirect)
					WebApp.close()
				} else if (result.validate) {
					setAlert('features.payment.tinkoff.form.validate')
				} else if (result.validate_terms) {
					setAlert('features.payment.tinkoff.form.validate_terms')
				}
			} else {
				showBoundary()
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}

	const setAlert = (message) => {
		notificationOccurred('error')
		setError(t(message))
		setTimeout(() => setError(false), 3000)
	}

	return (
		!data ?
			<Load/>
		:
			<>
				<div className='boxText'>
					<div className='boxText_header'>{ t('features.payment.tinkoff.form.title_' + props.type) }</div>
					<div className='boxText_body'>
						<p>
							{ t('features.payment.tinkoff.form.text1') }<br/>
							{props.period}<WordDeclension count={props.period} code='features.payment.tinkoff.form.months' /> - <b>{data.price} ₽</b>
							{
								discounts[props.type] ?
									discounts[props.type][props.period] !== 0 && ' (' + t('features.payment.tinkoff.form.discount') + ' ' + discounts[props.type][props.period] + '%)'
								:
									null
							}
						</p>
						<p><b>{ t('features.payment.tinkoff.form.data') }</b></p>
						<input type='email' className='input input--grey input--center'
							ref={inputEmail}
							defaultValue={data.email}
							placeholder={ t('features.payment.tinkoff.form.email') }
						/>

						{/* <div className='form-check'>
							<input type='checkbox' name='check_recurrent' id='check_recurrent' ref={checkRecurrent} />
							<label for='check_recurrent'>
								<Trans i18nKey="features.payment.tinkoff.form.recurrent" components={{ a: <a /> }} values={{ recurrent: docs.recurrent }} />
							</label>
						</div> */}
						<div className='form-check'>
							<input type='checkbox' name='check_terms' id='check_terms' ref={checkTerms} />
							<label htmlFor='check_terms'>
								<Trans i18nKey="features.payment.tinkoff.form.terms" components={{ a: <a /> }} values={{ terms: docs.terms, policy: docs.policy }} />
							</label>
						</div>

						<button className='btn btn--block btn--tt-none' onClick={handleSend}>{ t('features.payment.tinkoff.form.send') }</button>
					</div>
				</div>
				{error ? (
					<Alert danger={true}>{error}</Alert>
				) : null}
			</>
	)
}
