import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useEffect } from "react";
import Lottie from "lottie-react"
import AnimationFailure from '@/icons/animations/Failure.json'
import { animationSizes } from '@/constants'
import { useTranslation } from "react-i18next";

export default function MemberBlocked() {
	const { t } = useTranslation()
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	useEffect(() => {
		notificationOccurred('error')
	}, [])

	return (
		<div className='boxIntro'>
			<div className='boxIntro_sticker'>
				<Lottie animationData={AnimationFailure} loop={false} style={animationSizes['AnimationFailure']} />
			</div>
			<div className='boxIntro_title'>{ t('modules.member.blocked.title') }</div>
			<div className='boxIntro_text'>{ t('modules.member.blocked.text') }</div>
		</div>
    );
}
