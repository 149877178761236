import React, { useEffect } from 'react';
import Page from '@/components/Page';
import { useParams } from 'react-router-dom';
import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import TinkoffForm from '../components/TinkoffForm';

export default function PaymentPage() {
	const { t } = useTranslation()
	const params = useParams()
	const period = parseInt(params.period)
	const type = parseInt(params.type)
	const [isExpanded, expand] = useExpand();
	const { showBoundary } = useErrorBoundary()

	useEffect(() => {
		expand()
		if (!period || !type) showBoundary()
	}, [period, type])

	return (
    	<Page title={ t('features.payment.tinkoff.title') }>
			<TinkoffForm period={period} type={type} />
	    </Page>
	)
}
