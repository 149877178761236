import Load from '@/components/Load';
import { Turnstile } from '@marsidev/react-turnstile';
import { useEffect, useRef } from 'react';
import MemberApi from '../api';
import { useErrorBoundary } from 'react-error-boundary';
import useEnvInitData from '@/hooks/useEnvInitData'
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { useDispatch, useSelector } from 'react-redux';
import { getRaffle } from '@/features/Raffle/reducer';
import { getUser, setUser } from '@/modules/User/reducer';

export default function MemberCreate() {
  	const refCaptcha = useRef()
	const { showBoundary } = useErrorBoundary()
	const [InitDataUnsafe, InitData] = useEnvInitData();
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const raffle = useSelector(getRaffle)
	const dispatch = useDispatch()
	const user = useSelector(getUser)

	const handleCreate = () => {
		MemberApi.create({
			initData: InitData,
			param: InitDataUnsafe.start_param,
			token: refCaptcha.current ? refCaptcha.current.getResponse() : ''
		}).then(function(result) {
			if (result.user) {
				notificationOccurred('success')
				dispatch(setUser({
					...user,
					...result.user,
				}))
			} else {
				notificationOccurred('error')
			}
		}).catch((e) => {
			showBoundary(e)
		})
	}

	useEffect(() => {
		if (raffle && !raffle.captcha) {
			handleCreate()
		}
	}, [raffle])

	return (
		<div className='MemberCreate'>
			<Load/>
			{raffle.captcha ?
				<Turnstile
					ref={refCaptcha}
					siteKey='0x4AAAAAAAgDD8CYN_YpvXoS'
					onSuccess={handleCreate}
					options={{
						size: 'flexible'
					}}
				/>
			: null}
		</div>
	)
}
