import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { testInitDataUnsafe } from '@/constants'

export default function useEnvInitData() {
	const [InitDataUnsafe, InitData] = useInitData();

	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		return [testInitDataUnsafe, process.env.REACT_APP_TEST_ENV_DATA]
	} else {
		return [InitDataUnsafe, InitData]
	}
}
