import Lottie from "lottie-react"
import AnimationFailure from '@/icons/animations/Failure.json'
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { animationSizes } from '@/constants'
import { useTranslation } from 'react-i18next';

export default function ErrorComponent(props) {
	const [impactOccurred, notificationOccurred] = useHapticFeedback();
	const { t } = useTranslation()

	const [status, setStatus] = useState('default')

	useState(() => {
		notificationOccurred('error')
		if (props.error && props.error.response && props.error.response.status) {
			if (props.error.response.status === 410) {
				setStatus(410)
			}
		}
	}, [])

	return (
		<main>
			<div className='boxIntro'>
				<div className='boxIntro_sticker'>
					<Lottie animationData={AnimationFailure} loop={false} style={animationSizes['AnimationFailure']} />
				</div>
				<div className='boxIntro_title'>{ t('errors.' + status + '.title') }</div>
				<div className='boxIntro_text'>{ t('errors.' + status + '.text') }</div>
				<div className='boxIntro_text'>{ t('errors.' + status + '.text2') }</div>
			</div>
		</main>
    );
};
