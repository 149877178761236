import { useSelector } from 'react-redux'
import { getUser } from '@/modules/User/reducer';
import { getRaffle } from '@/features/Raffle/reducer';
import { raffleStatusPreend, raffleStatusDone, raffleTypeForRef, raffleTypeForPay } from '@/constants'
import RafflePreEnd from '@/features/Raffle/components/PreEnd';
import RaffleEnd from '@/features/Raffle/components/End';
import MemberActive from './components/Active';
import MemberNew from './components/New';
import { useState } from 'react';
import BottomSheet from "react-draggable-bottom-sheet";
import RaffleContent from '@/features/Raffle/components/Content';
import { useExpand, useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import MemberUnActive from './components/UnActive';
import MemberBlocked from './components/Blocked';
import MemberRef from './components/Ref';
import MemberPay from './components/Pay';
import MemberCreate from './components/Create';
import IconClose from "@/icons/close.svg";
import { HandySvg } from 'handy-svg';

export default function Member() {
	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)
	const raffleStatus = parseInt(raffle.status)
	const [impactOccurred] = useHapticFeedback();
	const [isExpanded, expand] = useExpand();

	const [isContentOpen, setIsContentOpen] = useState(false);
	const contentClose = () => setIsContentOpen(false);
	const openRaffle = () => {
		if (!isExpanded) expand()
		impactOccurred('light')
		setIsContentOpen(true)
	}

	return (
		<>
			{
				raffleStatus === raffleStatusPreend ?
					<RafflePreEnd openRaffle={openRaffle} />
				: raffleStatus === raffleStatusDone ?
					<RaffleEnd openRaffle={openRaffle} />
				: user.member_blocked ?
					<MemberBlocked />
				: !user.member ?
					<MemberCreate />
				: raffle.type === raffleTypeForPay ?
					<MemberPay openRaffle={openRaffle} />
				: user.tickets ?
					<MemberActive openRaffle={openRaffle} />
				: user.channels ?
					<MemberUnActive openRaffle={openRaffle} />
				: raffle.type === raffleTypeForRef && !user.member_new ?
					<MemberRef openRaffle={openRaffle} />
				:
					<MemberNew />
			}

			<BottomSheet isOpen={isContentOpen} close={contentClose} disabled>
				<button className='BottomSheetClose' onClick={contentClose}><HandySvg src={IconClose}/></button>
				<RaffleContent/>
			</BottomSheet>
		</>
	)
}
