import { configureStore } from '@reduxjs/toolkit'
import UserState from '@/modules/User/reducer'
import RaffleState from '@/features/Raffle/reducer'

export const store = configureStore({
	reducer: {
		UserState: UserState,
		RaffleState: RaffleState
	},
})

