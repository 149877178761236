import { useTranslation } from 'react-i18next';

export default function WordDeclension(props) {
	const { t } = useTranslation()

	let idx = false;
	if (props.count % 10 === 1 && props.count % 100 !== 11) {
		idx = 0;
	} else if (props.count % 10 >= 2 && props.count % 10 <= 4 && (props.count % 100 < 10 || props.count % 100 >= 20)) {
		idx = 1;
	} else {
		idx = 2;
	}
	return idx !== false ? ' ' + t(props.code + '.' + idx) : '';
}
