import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	user: false,
  };

export const userSlice = createSlice({
	name: 'UserState',
	initialState,

  	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		unsetUser: (state) => {
			state.user = false;
		}
	},
})

export const { setUser, unsetUser } = userSlice.actions

export const getUser = (state) => state.UserState.user

export default userSlice.reducer
